@import "~reset-scss/reset.css";

// colors
$title-color: #1976d2;

// mixins
@mixin thumb {
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
}

@mixin trunc($lineHeight, $lines) {
	line-height: $lineHeight;
	display: -webkit-box;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	max-height: $lineHeight * $lines;
}

html {
	touch-action: manipulation;
}

body {
	font-family: Roboto, sans-serif;

	/* Better Font Rendering */
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	&.body-request {
		overflow:hidden;
	}
}

#wrapper {

}

header {
	width: 100%;
	height: 90px;
	padding: 20px 80px 20px 80px;
	background-image: linear-gradient(267deg, #1976d2, #1a428a);

	#logo {
		float: left;
		width: 400px;
		height: 48px;
		background-size: contain;
	}

	ul#icon-menu {
		display: grid;
		grid-auto-flow: row;
		grid-template-columns: 64px;
		grid-column-gap: 36px;
		color: white;
		float: right;

		> li {
			grid-row: 1;
			font-size: 12px;
			font-weight: 500;
			text-align: center;
			text-transform: uppercase;
			letter-spacing: 2px;

			div.icon {
				width: 44px;
				height: 44px;
				margin: 0 auto 4px;
				background-repeat: no-repeat;
				background-size: cover;
			}
		}
	}
}

#top {
	margin-top: 30px;
	width: 100%;
	padding: 0 80px 0px 80px;
	box-sizing: border-box;
}

aside {
	box-sizing: border-box;
	border: solid 1px #cccccc;
	width: 300px;
	float: left;

	ul#top-headlines {

		> li {
			box-sizing: border-box;
			border-bottom: 1px solid #ccc;
			padding: 20px;

			a {
				@include trunc(1.625em, 3);
				max-width: 261px;
				font-size: 16px;
				letter-spacing: -0.18px;
			}

			&:last-child {
				border-bottom: none;
			}
		}
	}
}

#carousel {
	width: 780px;
	height: 440px;
	box-sizing: border-box;
	float: right;
}

#sub-carousel {
	width: 780px;
	float: right;

	display: flex;
	flex-direction: row;
	justify-content: space-between;

	margin-top: 15px;

	article {
		width: 240px;

		h1 {
			font-size: 16px;
			font-weight: 500;
			@include trunc(1.5em, 3);
			letter-spacing: -0.2px;
			color: #1d1f24;
			margin-top: 10px;
		}

		div.img {
			@include thumb;
			width: 100%;
			height: 130px;
		}
	}
}

#bottom {
	padding: 20px 80px 0 80px;
	clear: both;
}

ul#categories {
	width: 100%;
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	text-transform: uppercase;
	margin-bottom: 40px;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: 2px;
	text-align: center;

	a {
		transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
		border-bottom: solid 2px transparent;
		padding-bottom: 11px;

		&.active {
			color: #1976d2;
			border-bottom: solid 2px #1976d2;
			padding: 0 30px 11px 10px;
		}
	}
}

#featured {
	display: flex;
	flex-flow: row;
	justify-content: space-between;

	article {
		@include thumb;
		width: 540px;
		height: 300px;
		display: flex;
		flex-direction: column;

		h3 {
			font-size: 24px;
			font-weight: 500;
			@include trunc(1.25em, 3);
			letter-spacing: -0.2px;
			color: #ffffff;
			margin: auto 90px 20px 25px;
		}

		span {
			height: 40px;
			background: #1976d2;
			margin: 20px 0 0 20px;
			padding: 10px 25px;
			color: #fff;
			text-transform: uppercase;
			align-self: flex-start;
		}
	}
}

#sub-featured {
	float: left;
	margin-top: 30px;

	article {
		clear: left;
		height: 214px;
		width: 780px;
		overflow: hidden;
		margin-bottom: 50px;

		div.thumb {
			@include thumb;
			float: left;
			width: 240px;
			height: 214px;
			margin-right: 40px;
		}

		span.category {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 1.75px;
			color: #1976d2;
		}

		h3 {
			font-size: 26px;
			font-weight: 500;
			line-height: 1.15;
			letter-spacing: -0.22px;
			color: #1d1f24;
			margin: 10px 0;
		}

		p {
			font-size: 18px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			@include trunc(1.56em, 3);
			letter-spacing: normal;
			color: #6A6C71;
		}
	}
}

#banner_vertical, #banner_vertical_b {
	float: right;
	height: 250px;
	width: 300px;
	margin-top: 30px;
	overflow: hidden;
	clear: right;
}

#banner_vertical_b {
	margin-bottom: 30px;
}

#banner_horizontal, #banner_horizontal_b {
	width: 728px;
	height: 90px;
	margin: 0 auto;
	clear: both;
}

#banner_horizontal_b {
	margin-top: 30px;
}

.clearfix {
	clear: both;
}

footer {
	display: flex;
	flex-flow: row;
	justify-content: space-between;
	margin-bottom: 50px;

	article {
		width: 340px;
		box-sizing: content-box;
		border: 1px solid #ccc;
		padding-bottom: 32px;
		box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.05);
	}

	h3 {
		font-size: 24px;
		font-weight: 500;
		font-style: normal;
		font-stretch: normal;
		@include trunc(1.25em, 3);
		letter-spacing: -0.2px;
		color: #1d1f24;
		margin: 35px 20px 12px;
	}

	p {
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		@include trunc(1.625em, 3);
		letter-spacing: normal;
		color: #83858A;
		margin: 0 20px;
	}

	div.thumb {
		@include thumb;
		width: 340px;
		height: 196px;
	}
}

#content {

	min-height:100%;

	&.request {
		height: 100%;
		background-image: linear-gradient(267deg, #1976d2, #1a428a);
		color: white;
		text-align: center;

		.content-wrapper {
			margin:0;
			padding: 80px 0 0 0;
			height: 100%;

			h1 {
				font-size: 48px;
				font-weight: 500;
				letter-spacing: 1.33px;
			}

			.inputs {

			}

			.confirmation, .failure {

				text-align: center;

				img {
					margin: 0 auto 35px;

				}

				h1 {
					font-size: 30px;
					font-weight: bold;
					line-height: 1;
					letter-spacing: -0.1px;
					margin-bottom: 14px;
				}

				button {
					margin-top: 75px;
					width: 196px;
					height: 45px;
					border-radius: 6px;
					font-weight: bold;

					&.ok {
						color:white;
						background-color: #1976d2;
					}
				}
			}

			.form-wrapper {
				margin: 40px auto 0;
				background: white;
				width: 500px;
				border-radius: 7px;
				color: black;
				text-align: left;
				padding: 30px;
				box-shadow: 0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 1px 0 rgba(10, 22, 70, 0.06);

				input.validation-error {
					border: solid 2px #e34c4c !important;
					color: #e34c4c;
				}

				label.validation-error {
					color: #e34c4c !important;
					.validation-detail {
						display: inline;
					}
				}

				.validation-detail {
					display: none;
				}

				// service request form
				.service-request {
					display: flex;
					flex-flow: column;
					flex-direction: column;

					label {
						text-transform: uppercase;
						font-size: 14px;
						font-weight: bold;
						color: #323c47;
						margin: 20px 0 10px;

						&:first-child {
							margin: 0 0 10px;
						}
					}

					select, input {
						border: none;
						height: 45px;
						border-radius: 4px;
						background-color: #f2f2f2;
						padding: 10px 20px;
					}

					.service-request-options {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						.spinner {
							border-radius: 4px;
							background-color: #f2f2f2;
							box-sizing: border-box;

							button {
								width: 45px;
								font-size: 24px;
								text-align: center;
							}

							input {
								width: 60px;
								text-align: center;
							}
						}
					}

					.buttons {

						display:flex;
						flex-direction: row;
						justify-content: flex-end;
						margin-top: 30px;

						button {
							width: 196px;
							height: 45px;
							border-radius: 6px;
							font-weight: bold;

							&.cancel {
								color: #83858A;
							}

							&.submit {
								color: white;
								background-color: #1976d2;

								&:disabled {
									background-color: #83858A;
								}
							}
						}
					}
				}
			}
		}
	}

	&.goldstar {

		text-align: center;

		div.title {
			text-transform: uppercase;
			color: #1976d2;
			font-size: 14px;
			letter-spacing: 1.75px;
			font-weight: bold;
			margin-bottom: 11px;
		}

		div.tagline {
			text-transform: uppercase;
			font-size: 20px;
			letter-spacing: 0.29px;
			font-weight: bold;
			margin-bottom: 24px;
		}

		div.goldstar-items {

			div.goldstar-row {

				display:grid;
				grid-template-rows: auto;
				grid-template-columns: repeat(4, auto);
				justify-content: space-around;
				align-content: space-around;

				div.goldstar-item {

					width: 240px;
					box-sizing: border-box;
					text-align: left;
					margin-bottom: 20px;

					.thumb-wrapper {

						height: 130px;
						width: 240px;
						box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
						margin-bottom: 11px;
						position: relative;

						.date {
							background-color: #1976d2;
							color: white;
							display: inline-block;
							margin-top: 10px;
							padding: 2px 4px;
							font-size: 12px;
							font-weight: 500;
							letter-spacing: 1.5px;
							text-transform: uppercase;
							position: absolute;
							top: 0;
							left: 0;
						}

						.thumb {
							background-color: #6A6C71;
							height: 130px;
							width: 240px;
							object-fit: cover;
						}
					}

					h4 {
						font-size: 16px;
						font-weight: 500;
						letter-spacing: -0.18px;
						line-height: 1.5;
					}

					h5 {
						font-size: 14px;
						color: #4a4a4a;
						letter-spacing: 1.75px;
						font-style: italic;
					}

					p {
						margin-top: 10px;
						margin-bottom: 0;
						display: block;
						clear: both;
						font-size: 14px;
						line-height: 1.71em;
						letter-spacing: -0.16px;
					}

				}
			}
		}

	}

	&.viator {

		text-align: center;

		div.title {
			text-transform: uppercase;
			color: #1976d2;
			font-size: 14px;
			letter-spacing: 1.75px;
			font-weight: bold;
			margin-bottom: 11px;
		}

		div.tagline {
			text-transform: uppercase;
			font-size: 20px;
			letter-spacing: 0.29px;
			font-weight: bold;
			margin-bottom: 24px;
		}

		div.viator-items {

			display: grid;
			grid-template-columns: repeat(auto-fill, 240px);
			grid-gap: 1rem;
			justify-content: space-between;

			div.viator-item {

				width: 240px;
				box-sizing: border-box;
				text-align: left;
				margin-bottom: 60px;

				.thumb {
					height: 130px;
					width: 240px;
					background-color: #6A6C71;
					box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
					margin-bottom: 11px;
					object-fit: cover;
				}

				h4 {
					font-size: 16px;
					font-weight: 500;
					letter-spacing: -0.18px;
					line-height: 1.5;
				}

			}
		}

	}

	&.games {

		.right {
			#banner_vertical {
				margin-top: 20px;
			}
		}

		#categories {
			margin-top:40px;
		}

		#games {
			width: 787px;
			margin: 0 auto;

			.game-row {

				display:grid;
				grid-template-rows: auto;
				grid-template-columns: repeat(5, auto);
				justify-content: space-around;
				align-content: space-around;

				&:nth-child(odd) {
					background: #f7f7f7;
					border-radius: 8px;
				}

				div {
					max-width: 100px;
					box-sizing: content-box;
					overflow: hidden;
					margin: 0 auto;
					padding: 20px;

					img {
						@include thumb;
						width: 100px;
						height: 100px;
						margin-bottom: 10px;
						border-radius: 24px;
					}

					h1 {
						margin: 0;
						color: #1d1f24;
						font-size: 13px;
						font-weight: normal;
						letter-spacing: -0.2px;
					}
				}
			}
		}
	}

	div.header {
		@include thumb;
		color: #fff;
		width: 100%;
		height: 470px;
		background-color: #333333;
		overflow: hidden;
		padding: 30px 0 0 80px;

		.tag {
			text-transform: uppercase;
			background-color: #1976d2;
			width: fit-content;
			display: inline-block;
			padding: 10px 24px;
			font-size: 14px;
			letter-spacing: 1.75px;
			font-weight: 500;
		}

		.date {
			margin: 280px 0 0 0;
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 1.75px;
			text-transform: uppercase;
		}

		h1 {
			margin: 22px 0 18px 0;
			font-size: 48px;
			font-weight: 500;
			letter-spacing: 1.333px;
			text-transform: uppercase;
		}

		h2 {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 1.75px;
			text-transform: uppercase;
		}
	}

	.content-wrapper {
		margin: 30px 80px 70px;
	}

	.left {

		width: 700px;
		float: left;
		margin-bottom: 70px;

		&.w-800 {
			width: 800px;
		}

		.date {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 1.75px;
			color: #000;
			text-transform: uppercase;
		}

		h1 {
			font-size: 24px;
			line-height: 1.42;
			letter-spacing: -0.2px;
			color: #000;
			margin: 22px 0 12px;
		}

		.tag {
			font-size: 14px;
			letter-spacing: 1.74px;
			color: #1976d2;
			text-transform: uppercase;
			margin: 0 0 50px;
		}

		#video {
			margin-bottom: 60px;
			max-width: 700px;
			display: none;

			video {
				width: 100%;
			}
		}

		#article-content {
			font-size: 18px;
			line-height: 1.56;
			color: #4D4D4D;

			> div {
				&.row {

					h1 {
						font-size: 24px;
						font-weight: 500;
						letter-spacing: 3px;
						text-transform: uppercase;
					}

					h2 {
						font-size: 20px;
						color: #1976d2;
						text-transform: uppercase;
						font-weight: 500;
						letter-spacing: 0.56px;
						text-align: center;
						margin: 20px 0;
					}

					h3 {
						font-size: 16px;
						font-weight: 500;
						text-align: left;
						text-transform: uppercase;
						letter-spacing: 1.75px;
						color: #1d1f24;
						margin-bottom: 20px;
					}

					.bricks {
						display: flex;
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: space-between;
						.brick {
							flex: 0 45%;
							margin-bottom:40px;
							ul {
								list-style: initial;
								li {
									line-height: 2em;
								}
							}
						}
					}
				}
			}

			.contact-info {

				display: flex;
				flex-direction: row;
				flex-wrap: wrap;
				justify-content: space-around;
				padding-bottom:40px;

				div {
					flex: 1;

					h1 {
						color: #1976d2;
						font-size: 16px;
						font-weight: bold;
						letter-spacing: 1.75px;
						text-transform: uppercase;
						margin:0;
					}
				}
			}

			p, img {
				margin-bottom: 20px;
			}

			img {
				max-width: 700px;

				&.placeholder {
					width: 700px;
					height: 380px;
					background: #333;
				}
			}

			blockquote {
				margin: 0 0 30px;
				padding: 30px 0;
				font-size: 24px;
				font-style: italic;
				font-weight: bold;
				line-height: 1.42;
				letter-spacing: -0.2px;
				color: #000;
				border-top: 1px solid #83858A;
				border-bottom: 1px solid #83858A;
			}
		}

		#read-more {
			text-transform: uppercase;
			font-size: 14px;
			color: #1976d2;
			font-weight: bold;
			letter-spacing: 1.75px;
			text-align: center;
			display: block;
			width: 100%;
			margin-top: 20px;
		}

	}

	.right {
		float: right;
		margin-bottom: 70px;

		aside {
			float: none;
		}

		#banner_vertical {
			margin-top: 160px;
		}

	}

}

.view-more, .go-back-home {
	text-transform: uppercase;
	font-size: 16px;
	color: #1976d2;
	font-weight: bold;
	letter-spacing: 0.8px;
	text-align: center;
	display: block;

	border: solid 1px #ebebeb;
	width: 210px;
	padding: 20px 0;

	box-shadow: inset 0em -2px #1976d2;

	margin: 20px auto;
}

.no-results {

	width:100%;
	text-align: center;
	font-family: Roboto, sans-serif;

	h1 {
		font-size: 24px;
		font-weight: bold;
		letter-spacing: -0.43px;
	}

	p {
		font-size: 20px;
		letter-spacing: -0.36px;
		margin: 30px 0;
	}

	img {
		margin: 50px auto;
		width: 167px;
		height: 87px;
		object-fit: contain;
	}
}

#tos-footer {
	width: 100%;
	background: #1d1f24;
	box-sizing: border-box;
	padding:0;
	text-align: center;
	color: white;

	hr {
		margin: 0;
	}

	span {
		display: block;
		padding: 12px;
	}

	h1 {
		font-size: 16px;
		font-weight: bold;
		text-transform: uppercase;
		padding: 24px 0;
		a {
			color: white;
			text-decoration: none;
		}
	}
}